<template>
  <app-icon-btn
    :disabled="!canWorkWithRemoveCollection.allowed || disabled"
    :text="GET_TEXT_REASON(canWorkWithRemoveCollection.reason)"
    class="collection-action-button"
    bottom
    icon-color="rgba(0, 0, 0, 0.54)"
    :icon-height="iconSize"
    icon-name="mdi-delete"
    :show-tooltip="!canWorkWithRemoveCollection.allowed || disabled"
    @click="delleteColection">
    <p
      v-if="nameAction"
      class="small-p">
      Delete
    </p>
  </app-icon-btn>
</template>
<script>
import {
  mapMutations,
  mapActions,
  mapGetters,
  mapState,
} from 'vuex';
import CollectionsApi from '@/services/graphql/collections';
import ToggleViewCondition from '@/mixins/ToggleViewCondition';
import { GET_TEXT_REASON } from '@/constants/userPermissions';
import { getUserRolesListById } from '@/utils/accessLayer';
export default {
  name: 'CollectionDelete',
  mixins: [ToggleViewCondition],
  props: {
    item: {
      type: Object,
      default: null,
    },
    isLibrary: {
      type: Boolean,
      default: false,
    },
    nameAction: {
      type: Boolean,
      default: true,
    },
    iconSize: {
      type: Number,
      default: 16,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('UserRoles', ['canRemoveCollectionFromMyLibrary', 'findUserRoleInLibraryForActiveHeader']),
    ...mapState(['activeHeader']),
    ...mapGetters(['userId']),
    ...mapState('Workspace', ['activeWorkspaceId']),
    canWorkWithRemoveCollection() {
      const { userId } = this;
      const { type = '', members } = this.item || {
      };
      let role = type;
      role = this.findUserRoleInLibraryForActiveHeader;
      if (members) {
        role = getUserRolesListById({
          userId,
          users: members,
        });
      }
      return this.canRemoveCollectionFromMyLibrary(role);
    },
  },
  methods: {
    ...mapActions({
      removeCollection: 'Collections/removeCollection',
      removePublishedCollection: 'Collections/removePublishedCollection',
      handleError: 'handleError',
    }),
    ...mapMutations(['spinner']),
    GET_TEXT_REASON,
    async delleteColection() {
      if (!this.canWorkWithRemoveCollection.allowed || this.disabled) {
        return;
      }
      let confirmDeleteItem = await this.$openConfirm({
        text: `Do you want to delete ${this.item.name} ? this Collection will be DELETED FROM ALL Libraries immediately. You CANNOT UNDO this action.`,
      });
      this.spinner(true);
      if (confirmDeleteItem) {
        try {
          const { data } = await CollectionsApi.deleteCollection({
            collectionId: this.item.id,
            libraryId: this.item.libraryId,
            workspaceId: this.activeWorkspaceId,
          });
          if (this.item.publish) {
            this.removePublishedCollection(data.response);
          } else {
            this.removeCollection(data.response);
          }
          if (this.toggleViewCondition('librariesRelatedPages')
            && this.$route.name === 'collection-library') {
            this.$router.push({
              path: `/collections/${this.item.libraryId}`,
            });
          }
        } catch (err) {
          this.handleError(err);
        }
      }
      this.spinner(false);
    },
  },
};
</script>
<style scoped lang="scss">
.v-icon {
  cursor: pointer;
}
.small-p {
  padding: 0 !important;
}
</style>
